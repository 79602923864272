import React from 'react';
import { useNavigate } from 'react-router-dom'
export default function Hero() {
    const navigate = useNavigate()
    return (
        <>
            <section class="hero">
                <div class="col-sm-6 hero_inner">
                    <div class="hero_title">
                        The Future of Business is Embedded Intelligence
                    </div>
                    <div class="hero_body">
                        IziFin is a configurable and affordable Embedded Intelligence
                        Operating System, enabling a range of easy to integrate
                        Artificial Intelligent (A.I.) Apps
                    </div>
                    <div class="top_hero_buttons">
                        <a href="https://panel.izifin.com/auth/getstarted" class="btn hero_btn solid">
                            Get Started
                        </a>
                        <div
                            class="btn hero_btn light"
                            onClick={() => navigate('./request')}
                        >
                            Request a demo
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
