import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";

const ConsentBanner = () => {
  const [isClient, setIsClient] = useState(false);
  const [consent, setConsent] = useState(undefined);

  useEffect(() => {
    setIsClient(true);
    setConsent(Cookies.get('consent'));
  }, []);

  const handleAccept = () => {
    Cookies.set('consent', 'true', { expires: 365 });
    setConsent('true');
  };

  const handleDecline = () => {
    Cookies.set('consent', 'false', { expires: 365 });
    setConsent('false');
  };

  if (!isClient || consent !== undefined) return null;

  return (
    <div className="consent-banner">
      <div className="consent-content">
        <div className="icon">
          <img src="/cookie.png" alt="Cookie Icon" />
        </div>
        <div className="text">
          <h2>We Value Your Privacy!</h2>
          <p>
            Izifin uses cookies to enhance your browsing experience, analyze
            site traffic, and personalize content. By continuing to use our site,
            you consent to our use of cookies.
          </p>
          <div className="actions">
            <button className="accept" onClick={handleAccept}>Accept</button>
            <button className="decline" onClick={handleDecline}>Decline</button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default ConsentBanner;