import React, { useContext, useState } from 'react';
import { mainFunctions } from "../providers/MainProvider";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function Footer() {
    const navigate = useNavigate()

    const {
        productsRef,
        aboutRef,
        pricingRef,
        validEmail
    } = useContext(mainFunctions)

    const [formDetails, setformDetails] = useState({
        title: 'newsletter',
        source: 'izifin',
        email: ''
    })

    const updateformDetails = (name, value) => {
        setformDetails({ ...formDetails, [name]: value })
    }

    const submitForm = () => {
        if (typeof formDetails.email !== "undefined"
            && validEmail(formDetails.email)
        ) {
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(formDetails),
                redirect: 'follow'
            };

            fetch("https://pqa45jgunk.execute-api.us-east-2.amazonaws.com/default/FormEmailSenderV2", requestOptions)
                .then(response => response.json())
                .then(result => {
                    toast.success('Signed up to our newsletter', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setformDetails({
                        title: "izifin_newsletter"
                    })
                })
                .catch(error => console.log('error', error));
        } else {
            toast.warning('Enter a valid Email Address', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <>
            <section className="footer section_dark box_border no_bottom_padding">
                <div className="footer_top">
                    <div>
                        <div className="section_sub_title">Get Started Today</div>
                        <div className="section_body">
                            Sign-up today and get access to our suite of easy to use and integrate intelligent products, packaged conveniently for companies to scale, optimise and grow their business
                        </div>
                    </div>
                    <a href="https://panel.izifin.com/auth/getstarted">
                        <div className="btn btn_solid"
                        // onClick={() => navigate('./request')}
                        >Get started</div>
                    </a>

                </div>
                <div className="footer_bottom row">
                    <div className="col-md-3">
                        <div className="izifin_logo"></div>
                        <div className="logo_text">The Future is Embedded Banking</div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-9 footer_list row">

                        <div className="col-6 col-sm-3 list_item">
                            <div className="list_title">Navigation</div>
                            <div className="list_body cursor-pointer"
                                onClick={async () => {
                                    await navigate('/')
                                    await aboutRef.current.scrollIntoView()
                                }}
                            >About Us</div>
                            <div className="list_body contacts cursor-pointer"
                                onClick={async () => {
                                    await navigate('/')
                                    await productsRef.current.scrollIntoView()
                                }}
                            >Products</div>
                            <div className="list_body careers cursor-pointer"
                                onClick={async () => {
                                    await navigate('/')
                                    await pricingRef.current.scrollIntoView()
                                }}
                            >Pricing</div>
                        </div>

                        <div className=" col-6 col-sm-3 list_item">
                            <div className="list_title">Products</div>
                            <div className="list_body cursor-pointer"
                                onClick={async () => {
                                    await navigate('/kyc')
                                }}
                            >IZI KYC</div>
                            <div className="list_body cursor-pointer"
                                onClick={async () => {
                                    await navigate('/bnpl')
                                }}
                            >IZI BNPL</div>
                            <div className="list_body cursor-pointer"
                                onClick={async () => {
                                    await navigate('/merchant')
                                }}
                            >IZI MERCHANT</div>
                        </div>

                        <div className=" col-sm-3 list_item">
                            <div className="list_title">Contact Us</div>
                            <div className="list_body">Email: contact@izifin.com</div>
                            <div className="icon_lists social_media_icons">
                                <div className="linkedIn icon" ></div>
                                <div className="facebook icon"></div>
                                <div className="instagram icon"></div>
                                <div className="twitter icon"></div>
                                <div className="youtube icon"></div>
                            </div>
                        </div>

                        <div className=" col-sm-3 list_item">
                            <div className="list_title">Newsletter</div>
                            <div className="list_body  sign_up_text">Sign up to our newsletter and keep informed</div>
                            <div className="fieldset">
                                <input placeholder="Email Address"
                                    value={typeof formDetails.email !== 'undefined' ? formDetails.email : ''}
                                    onChange={(e) => updateformDetails('email', e.target.value)} />
                                <div className={`send_btn ${validEmail(formDetails.email) ? '' : 'dumb_send_btn'}`} onClick={() => submitForm()}>Send</div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
