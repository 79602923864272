import React, { useContext, useState } from 'react';
import Header from '../component/Header'
import Footer from '../component/Footer'
import { mainFunctions } from "../providers/MainProvider";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { toast } from 'react-toastify';
import ConsentBanner from '../component/ConsentBanner';
export default function Request() {
    const {
        validEmail,
        validPhone,
        validText,
    } = useContext(mainFunctions)

    const [formDetails, setformDetails] = useState({
        title: 'request_for_demo',
        source: 'izifin'
    })

    const updateformDetails = (name, value) => {
        setformDetails({ ...formDetails, [name]: value })
    }

    const allValid = () => {

        return validEmail(formDetails.email) &&
            validPhone(formDetails.phoneNumber) &&
            validText(formDetails.firstname) &&
            validText(formDetails.lastname) &&
            validText(formDetails.businessName) &&
            validText(formDetails.businessAddress) &&
            validText(formDetails.product)
    }

    const submitForm = () => {
        if (allValid()) {
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(formDetails),
                redirect: 'follow'
            };

            fetch("https://pqa45jgunk.execute-api.us-east-2.amazonaws.com/default/FormEmailSenderV2", requestOptions)
                .then(response => response.json())
                .then(result => {
                    toast.success('Request for demo submitted', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setformDetails({
                        title: "Join processor network"
                    })
                })
                .catch(error => console.log('error', error));
        } else {
            toast.warning('Check invalid field values', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    return (
        <>
        <ConsentBanner />
            <Header />
            <section className="hero2 bnpl_bg">
                <div className="hero_title">Request for demo</div>
                <div className="hero_text">
                    Experiene a live customized demo, get answers to your specific questions and
                    find out why Izimonie is the right choice for your organization
                </div>
            </section>
            <section className="section_light">
                <form className='izimonie_form'>
                    <div className='fieldbox'>
                        <label>Firstname
                            <div className={`label_icon ${!validText(formDetails.firstname) ? 'important' : 'check'}`}></div>
                        </label>
                        <input type="text"
                            name="firstname"
                            value={typeof formDetails.firstname === "undefined" ? '' : formDetails.firstname}
                            onChange={(e) => updateformDetails('firstname', e.target.value)} />
                    </div>
                    <div className='fieldbox'>
                        <label>Lastname
                            <div className={`label_icon ${!validText(formDetails.lastname) ? 'important' : 'check'}`}></div>
                        </label>
                        <input
                            type="text"
                            name="lastname"
                            value={typeof formDetails.lastname === "undefined" ? '' : formDetails.lastname}
                            onChange={(e) => updateformDetails('lastname', e.target.value)} />
                    </div>
                    <div className='fieldbox'>
                        <label>Email
                            <div className={`label_icon ${!validEmail(formDetails.email) ? 'important' : 'check'}`}></div>
                        </label>
                        <input
                            type="email"
                            value={typeof formDetails.email === "undefined" ? '' : formDetails.email}
                            onChange={(e) => updateformDetails('email', e.target.value)} />
                    </div>
                    <div className='fieldbox'>
                        <label>Phone Number
                            <div className={`label_icon ${!validPhone(formDetails.phoneNumber) ? 'important' : 'check'}`}></div>
                        </label>
                        <PhoneInput
                            country={'ng'}
                            value={typeof formDetails.phoneNumber === "undefined" ? '' : formDetails.phoneNumber}
                            placeholder="+234 1234567891"
                            onChange={phone => updateformDetails('phoneNumber', phone)}
                        />
                    </div>
                    <div className='fieldbox'>
                        <label>Product
                            <div className={`label_icon ${!validText(formDetails.product) ? 'important' : 'check'}`}></div>
                        </label>
                        <input
                            type="text"
                            name="product"
                            value={typeof formDetails.product === "undefined" ? '' : formDetails.product}
                            onChange={(e) => updateformDetails('product', e.target.value)} />
                    </div>
                    <div className='fieldbox'>
                        <label>Business Name
                            <div className={`label_icon ${!validText(formDetails.businessName) ? 'important' : 'check'}`}></div>
                        </label>
                        <input
                            type="text"
                            name="businessName"
                            value={typeof formDetails.businessName === "undefined" ? '' : formDetails.businessName}
                            onChange={(e) => updateformDetails('businessName', e.target.value)} />
                    </div>
                    <div className='fieldbox'>
                        <label>Business Location (Country and City)
                            <div className={`label_icon ${!validText(formDetails.businessAddress) ? 'important' : 'check'}`}></div>
                        </label>
                        <textarea
                            name="businessAddress"
                            value={typeof formDetails.businessAddress === "undefined" ? '' : formDetails.businessAddress}
                            onChange={(e) => updateformDetails('businessAddress', e.target.value)}
                        ></textarea>
                    </div>
                    <div className='fieldbox'>
                        <button className={`btn ${allValid() ? 'btn_solid' : 'btn_dumb'}`} onClick={(e) => {
                            e.preventDefault()
                            submitForm()
                        }}>Submit</button>
                    </div>
                </form>
            </section>
            <Footer />
        </>
    );
}
