import React, { useContext } from 'react';
import Header from '../component/Header'
import Footer from '../component/Footer'
import About from '../component/About';
import Products from '../component/Products';
import Pricing from '../component/Pricing';
import { mainFunctions } from "../providers/MainProvider";
import ConsentBanner from '../component/ConsentBanner';

export default function IziKYC() {
    const {
        productsRef,
        aboutRef,
        pricingRef,
        innerRef
    } = useContext(mainFunctions)
    return (
        <>
        <ConsentBanner />
            <Header />
            <section className="hero2 kyc_bg">
                <div className="hero_title">IZI KYC</div>
                <div className="hero_text">
                    Validate users with cutting edge intelligence through facial detection, proof of life, face matches and ID verification.
                </div>
            </section>
            <section className="section_light">
                <div className="section_list2 row mb-5">
                    <div className="col-xs-12 col-sm-12 col-md-4 list_box" >
                        <div className='list_item' >
                            <div className="image izi_kyc_bg facematching"></div>
                            <div className="section_list_title">Face matching</div>
                            <div className="section_list_sub">
                                With a selfie, IZI KYC is able to match a user's face with the photograph on the ID provided. This inherently prevents the use of fake IDs.
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 list_box">
                        <div className='list_item'>
                            <div className="image customer_wallet"></div>
                            <div className="section_list_title">Proof of life</div>
                            <div className="section_list_sub">
                                IZI KYC authenticates the humanness of a prospective user using functions like instruction for movement, eye blinks and others.
                            </div>

                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 list_box">
                        <div className='list_item'>
                            <div className="image bnpl"></div>
                            <div className="section_list_title">ID Verification</div>
                            <div className="section_list_sub">
                                This technology collects and ascertain the genuineness of each ID provided by users.
                            </div>

                        </div>
                    </div>

                </div>
                <center>
                    <a href="https://panel.izifin.com/auth/getstarted">
                        <div className="btn btn_solid get_started_btn">Get started today</div>
                    </a>
                </center>
            </section>
            <Footer />
        </>
    );
}
