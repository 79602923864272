import React from 'react';

export default function Footnotes() {
    return (
        <>
            <div class="footer_notes">
                <div class="copyright">© {new Date().getFullYear()} Izifin Technologies Inc.</div>
                <div class="footer_notes_right">
                    {/* <div class="footer_notes_content">Terms of Use</div>
                    <div class="footer_notes_content">Privacy Policy</div> */}
                </div>
            </div>
        </>
    );
}
