import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Pricing() {
    const navigate = useNavigate()
    return (
        <>
            <section className="section_light no_bottom_padding">
                <div className="section_top">
                    <div className="section_title text_center">PRICING</div>
                    <div className="section_sub_title text_center">
                        Affordable Pay-as-you-go pricing
                    </div>
                    <div className="section_body text_center">
                        We offer flexible and affordable pay-as-you go pricing, with our products helping companies scale and optimise their business, without needing specialised data & analytical skills, or incurring prohibitive costs.
                    </div>
                    <center>
                        <a href="https://panel.izifin.com/auth/getstarted">
                            <div className="btn btn_solid mt-5"
                            // onClick={() => navigate('./request')}
                            >Get Started</div>
                        </a>
                    </center>
                </div>
                <div className="section_big_img pricing_bg"></div>

            </section>
        </>
    );
}
