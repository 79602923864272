import React from "react";
import Routing from "./Routing";
import './assets/styles.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div className="App main">
      <ToastContainer />
      <Routing />
    </div>
  );
}

export default App;
