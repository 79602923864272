import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { mainFunctions } from "../providers/MainProvider";
export default function Header(props) {
    const navigate = useNavigate()
    const [showAnchor, setShowAnchor] = useState(false)
    const {
        productsRef,
        aboutRef,
        pricingRef,
    } = useContext(mainFunctions)
    return (
        <div className="header">
            <Link to="/">
                <div className="logo"></div>
            </Link>
            <div className="anchor"
                onClick={() => setShowAnchor(!showAnchor)}
            ></div>
            <div className={`nav animated ${!showAnchor ? 'show_only_large' : 'bounceIn'}`}>
                <div className="nav_item"
                    onClick={async () => {
                        await navigate('/')
                        await aboutRef.current.scrollIntoView()
                    }}
                >
                    About us
                </div>
                <div className="nav_item"
                    onClick={async () => {
                        await navigate('/')
                        await productsRef.current.scrollIntoView()
                    }}
                >
                    Products
                </div>
                <div className="nav_item"
                    onClick={async () => {
                        await navigate('/')
                        await pricingRef.current.scrollIntoView()
                    }}
                >
                    Pricing
                </div>
                <a href="https://panel.izifin.com/auth/login" className="nav_item show_only_small login_btn">
                    <div >Login</div>
                </a>

            </div>
            <a href="https://panel.izifin.com/auth/login">
                <div className="btn top_btn show_only_large">Login</div>
            </a>
        </div>
    );
}
