import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'

// pages
import Home from './Pages/Home';
import Izibnpl from './Pages/Izibnpl';
import Izikyc from './Pages/Izikyc';
import Izimerchant from './Pages/Izimerchant';
import Request from './Pages/Request';

export default function Routing() {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/kyc" element={<Izikyc />} />
                <Route exact path="/bnpl" element={<Izibnpl />} />
                <Route exact path="/merchant" element={<Izimerchant />} />
                <Route exact path="/request" element={<Request />} />
            </Routes>
        </>
    );
}
