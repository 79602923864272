import React from 'react';

export default function About() {
    return (
        <>
            <section className="section_light">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="section_title text_left">WHO ARE WE</div>
                        <div className="section_sub_title text_left">
                            Insights-driven and intelligent products are the essential ingredient in growing business.
                        </div>
                        <div className="section_body">
                            Izifin was born out of the desire to offer flexible and affordable Embedded Intelligence products for FinTech.
                            <br /><br />
                            With simple API integrations, companies, in spite their size or data experience, can now offer a suite of Artificially Intelligent products, in order to scale, optimize, and automate complex processes.
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="who_are_we_bg"></div>
                    </div>
                </div>
            </section>

        </>
    );
}
