import React from 'react';
import { Link } from 'react-router-dom';
export default function Products() {
    return (
        <>
            <div className="section_dark">
                <div className="section_top">
                    <div className="section_title text_center">PRODUCTS</div>
                    <div className="section_sub_title text_center">
                        Embedded Intelligent Apps
                    </div>
                    <div className="section_body text_center">
                        Data driven applications, adapting the A.I techniques in the IZI-OS, with simple to use API Integration to any software, tooling, or process.
                    </div>
                </div>
                <div className="section_list row">

                    <div className="col-xs-12 col-md-4">
                        <div className='list_item'>
                            <div className="section_list_title">IZI KYC</div>
                            <div className="section_list_sub">Perform KYC digitally on your website or mobile app</div>
                            <div className="learn_more"><Link to="/kyc">Learn More</Link></div>
                            <div className="image izi_kyc_bg"></div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div className='list_item'>
                            <div className="section_list_title">IZI BNPL</div>
                            <div className="section_list_sub">Allow customers take goods home on credit.</div>
                            <div className="learn_more"><Link to="/bnpl">Learn More</Link></div>
                            <div className="image izi_bnpl_bg"></div>
                        </div>
                    </div>
                    <div className="col-xs col-md-4">
                        <div className='list_item'>
                            <div className="section_list_title">IZI Merchant</div>
                            <div className="section_list_sub">Digitise your business, customer acquisition, and payment channels</div>
                            <div className="learn_more"><Link to="/merchant">Learn More</Link></div>
                            <div className="image izi_merchant_bg"></div>
                        </div>
                    </div>
                </div>
                <div className="logo_carousel">
                    <div className="title">Trusted by</div>
                    <div className="logos">
                        <div className="logo accelerex"></div>
                        <div className="logo seamlesshr"></div>
                        <div className="logo vfd_group"></div>
                        <div className="logo rex_credit"></div>
                        <div className="logo sparkle"></div>
                        <div className="logo growsari"></div>
                        <div className="logo sterling"></div>
                        <div className="logo ajobox"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
